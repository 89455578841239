<!-- 登录页面 -->
<template>
  <div :class="!edition?'full_time':'odd_job'">
    <div class="wrap">
      <!-- 上面logo -->
      <div class="logo" v-if="!edition">
        <img src="@/assets/images/login/logo.png" alt />
      </div>
      <div class="logo" v-else>
        <img src="@/assets/images/login/logo_oddjob.png" alt />
      </div>
      <!-- 左面公司 -->
      <div class="company">
        <!-- <div>
          <span>24</span>
          <span>猎</span>
          <span>为</span>
          <span>中</span>
          <span>国</span>
          <span>企</span>
          <span>业</span>
          <span>加</span>
          <span>油</span>
        </div> -->
        <span class="dot1"></span>
        <span class="dot2"></span>
      </div>
      <!-- form -->
      <div class="login_form" style="" v-if="!edition">
        <div class="switch_edition">
          <el-button type="text" @click="edition = 1">切换企业零工版</el-button>
        </div>
        <div class="login_title">企业全职用户登录</div>
        <div class="ipt_wrap">
          <input
            class="ipt"
            type="text"
            maxLength="11"
            placeholder="请输入帐号"
            v-model.trim="user.username"
          />
        </div>
        <div class="ipt_wrap">
          <input
            class="ipt"
            type="password"
            placeholder="请输入密码"
            v-model="user.password"
          />
        </div>
        <el-button class="enter" :loading="loading" @click="enterIN"
          >登&emsp;录</el-button
        >
        <div class="agreement">
          <el-checkbox v-model="checkbox">我同意</el-checkbox>
          <span
            @click="
              $router.push({ path: '/conceal', query: { edition: edition } })
            "
            >《24猎隐私政策》</span
          >
          和
          <span
            @click="
              $router.push({ path: '/agree', query: { edition: edition } })
            "
            >《24猎使用协议》</span
          >
        </div>
        <div class="reg" @click="$router.push('/register')">
          全职企业免费注册
        </div>
      </div>
      <!-- 零工版本 -->
      <div class="login_form" v-if="edition">
        <div class="switch_edition">
          <el-button type="text" @click="edition = 0">切换企业全职版</el-button>
        </div>
        <div class="login_title">企业零工用户登录</div>
        <div class="ipt_wrap">
          <input
            class="ipt"
            type="text"
            maxLength="11"
            placeholder="请输入帐号"
            v-model.trim="user.username"
          />
        </div>
        <div class="ipt_wrap">
          <input
            class="ipt"
            type="password"
            placeholder="请输入密码"
            v-model="user.password"
          />
        </div>
        <el-button class="enter" :loading="loading" @click="enterIN"
          >登&emsp;录</el-button
        >
        <div class="agreement">
          <el-checkbox v-model="checkbox">我同意</el-checkbox>
          <span
            @click="
              $router.push({ path: '/conceal', query: { edition: edition } })
            "
            >《24猎隐私政策》</span
          >
          和
          <span
            @click="
              $router.push({ path: '/agree', query: { edition: edition } })
            "
            >《24猎使用协议》</span
          >
        </div>
        <div
          class="reg"
          @click="$router.push({ path: '/register', query: { edition: 1 } })"
        >
          零工企业免费注册
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { login, show_inform, lieLogin, odd_login_pc } from "@/api/login.js";
import { recharge_resume_remind } from "@/api/company/company.js";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      //登录按钮状态
      loading: false,
      //是否同意协议
      checkbox: false,
      user: {
        username: "",
        password: "",
      },
      // 版本号
      refresh: 1.13,
      // 版本
      edition: 0, //0是全职 1是零工
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    enterIN() {
      if (this.loading === false) {
        this.enter();
      } else {
        return;
      }
    },
    enter() {
      console.log(this.checkbox);
      if (!this.checkbox) {
        this.$message({
          message: "请同意下方协议",
          type: "warning",
        });
      } else {
        if (this.user.username === "") {
          this.$message({
            message: "请填写帐号",
            type: "warning",
          });
          return;
        }
        if (this.user.password === "") {
          this.$message({
            message: "请填写密码",
            type: "warning",
          });
          return;
        }
        this.loading = true;
        if (this.edition == 1) {
          // 零工登录
          odd_login_pc(this.user).then((res) => {
            if (res.code === 0) {
              console.log(res);
              sessionStorage.clear();
              sessionStorage.setItem("token", res.data.token);
              sessionStorage.setItem("is_seed", res.data.is_seed);
              sessionStorage.setItem("odd_job", res.data.odd_job);
              sessionStorage.setItem("is_agent", res.data.is_agent.toString());
              sessionStorage.setItem("user_detail_id", res.data.user_detail_id);
              sessionStorage.setItem("is_hotline", res.data.is_hotline);
              sessionStorage.setItem("is_elite", 0);
              sessionStorage.setItem("vip_expire", res.data.vip_expire);
              sessionStorage.setItem("odd_job_status", res.data.odd_job_status);
              // 是否刷新
              sessionStorage.setItem("is_refresh", res.data.is_refresh);

              if(res.data.odd_job_status != 0){
                this.$router.push("/radar");
                return
              }

              recharge_resume_remind({ is_seed: res.data.is_seed }).then(
                (Twores) => {
                  
                  if (Twores.code == 0) {
                    this.$message({
                      message: res.msg,
                      type: "success",
                    });
                  } else {
                    this.$message({
                      message: Twores.msg,
                      type: "error",
                    });
                  }
                }
              );
              lieLogin().then((lieRes) => {
                if (lieRes.code === "0") {
                  sessionStorage.setItem("lie_token", lieRes.token);
                  this.loading = false;
                  if (res.data.is_setting === 0) {
                    this.$router.push("/setshow");
                  } else {
                    this.$router.push("/company");
                    sessionStorage.setItem("Refresh", "yes");
                  }
                }
              });
            } else {
              // if (res.msg.username || res.code === 202002) {
              //   var message = '帐号输入有误，请重新填写'
              //   this.loading = false
              // } else if (res.msg.password || res.code === 202005) {
              //   var message = '密码输入有误，请重新填写'
              //   this.loading = false
              // } else if (res.msg.username && res.msg.password) {
              //   var message = '输入有误，请重新填写'
              //   this.loading = false
              // }
              this.loading = false;
              this.$message({
                message: res.msg,
                type: "error",
              });
            }
          });
        } else {
          // 全职登录
          login(this.user).then((res) => {
            if (res.code === 0) {
              console.log(res);
              sessionStorage.clear();
              sessionStorage.setItem("token", res.data.token);
              sessionStorage.setItem("is_seed", res.data.is_seed);
              sessionStorage.setItem("odd_job", res.data.odd_job);
              sessionStorage.setItem("is_agent", res.data.is_agent.toString());
              sessionStorage.setItem("user_detail_id", res.data.user_detail_id);
              sessionStorage.setItem("is_hotline", res.data.is_hotline);
              sessionStorage.setItem("is_elite", 0);
              sessionStorage.setItem("vip_expire", res.data.vip_expire);
              // 是否刷新
              sessionStorage.setItem("is_refresh", res.data.is_refresh);
              recharge_resume_remind({ is_seed: res.data.is_seed }).then(
                (Twores) => {
                  if (Twores.code == 0) {
                    this.$message({
                      message: res.msg,
                      type: "success",
                    });
                  } else {
                    this.$message({
                      message: Twores.msg,
                      type: "error",
                    });
                  }
                }
              );
              lieLogin().then((lieRes) => {
                if (lieRes.code === "0") {
                  sessionStorage.setItem("lie_token", lieRes.token);
                  this.loading = false;
                  if (res.data.is_setting === 0) {
                    this.$router.push("/setshow");
                  } else {
                    this.$router.push("/company");
                    sessionStorage.setItem("Refresh", "yes");
                  }
                }
              });
            } else {
              // if (res.msg.username || res.code === 202002) {
              //   var message = '帐号输入有误，请重新填写'
              //   this.loading = false
              // } else if (res.msg.password || res.code === 202005) {
              //   var message = '密码输入有误，请重新填写'
              //   this.loading = false
              // } else if (res.msg.username && res.msg.password) {
              //   var message = '输入有误，请重新填写'
              //   this.loading = false
              // }
              this.loading = false;
              this.$message({
                message: res.msg,
                type: "error",
              });
            }
          });
        }
      }
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    show_inform({}).then((res) => {
      if (!res.code) {
        this.$alert(`<h3 >${res.data.message}</h3>`, "通知", {
          showClose: false,
          confirmButtonText: "知道了",
          dangerouslyUseHTMLString: true,
          confirmButtonClass: "message",
          callback: (action) => {
            console.log(action);
          },
        });
      }
    });
    sessionStorage.setItem("is_elite", 0);
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped>
/* //@import url(); 引入公共css类 */
.full_time{
  background: url(../../../assets/images/login/full_time_bg.png) no-repeat center/100% #FA8B08;
  height: 100%;
}
.odd_job{
  background: url(../../../assets/images/login/odd_job_bg.png) no-repeat center/100% #E3AC2C;
  height: 100%;
}
.LOGIN_BG {
  width: 100%;
  min-height: 100%;
  background-size: 100% 100%;
  position: relative;
}
.wrap {
  width: 1200px;
  margin: 0 auto;
  height: 100%;
  padding-top: 100px;
  box-sizing: border-box;
  /* background-color: red; */
}
/* 解决高度塌陷 */
.wrap::after {
  content: "";
  display: block;
  visibility: hidden;
  height: 0;
  clear: both;
}
.company {
  /* background-color: #f4f4f4; */
  float: left;

  line-height: 500px;
  position: relative;
  left: 20px;
}
.logo {
  width: 100px;
  /* height: 50px; */
}
.logo img {
  width: 100%;
  height: 100%;
}
/* .company .dot1 {
  display: inline-block;
  position: absolute;
  z-index: 200;
  left: -50px;
  top: 30px;
  width: 300px;
  height: 100px;
  background-image: url('~@/assets/images/login/dot.png');
  background-repeat: no-repeat;
  background-size: contain;
} */
/* .company .dot2 {
  display: inline-block;
  position: absolute;
  z-index: 200;
  right: 50px;
  top: 430px;
  width: 300px;
  height: 100px;
  background-image: url('~@/assets/images/login/dot.png');
  transform: rotate(90deg);
  background-repeat: no-repeat;
  background-size: cover;
} */
.company div {
  /* width: 700px; */
  height: 560px;
  margin: 0;
  font-size: 41px;
  color: #000;
  /* font-weight: bold; */
  cursor: pointer;
}
/* 划过让字体变大 */
.company div span:nth-child(2) {
  margin-right: 16px;
}
/* .company div span:hover {
  font-size: 60px;
  color: #ff8f00;
} */
.login_form {
  margin-top: 40px;
  position: relative;
  float: right;
  width: 500px;
  /* height: 560px; */
  /* height: 50%; */
  box-sizing: border-box;
  padding: 90px 40px 30px;
  text-align: center;
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 10px;
}
.login_form .switch_edition {
  position: absolute;
  right: 20px;
  top: 10px;
}
.login_form .login_title {
  font-size: 23px;
  color: #666;
  text-align: center;
  margin-bottom: 46px;
}
.ipt_wrap {
  padding-left: 50%;
}
.ipt {
  transform: translate(-50%);
  display: block;
  margin-top: 40px;
  height: 47px;
  width: 280px;
  box-shadow: inset 0 24px 64px 0 rgba(14, 20, 33, 0.1);
  border-radius: 10px;
  text-indent: 2rem;
  font-size: 17px;
  color: #666;
}
.agreement {
  margin-bottom: 20px;
  font-size: 14px;
  color: #666;
}
.agreement span {
  color: #ff8f00;
  cursor: pointer;
  font-size: 14px;
}
.enter {
  width: 300px;
  height: 50px;
  border-radius: 10px;
  background-color: #ff8f00;
  color: #fff;
  border: none;
  font-size: 18px;
  margin-top: 40px;
  margin-bottom: 60px;
}
/* .enter:hover {
  background-color: #2468f2;
} */
/* 注册 */
.reg {
  font-size: 14px;
  text-align: right;
  cursor: pointer;
  color: #666;
}
.reg:hover {
  color: #ff8f00;
}

div /deep/ .el-checkbox__inner:hover {
  border-color: #ff8f00;
}

div/deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #ff8f00;
}
div/deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #ff8f00;
  border-color: #ff8f00;
}
div/deep/ .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #ff8f00;
}
</style>
